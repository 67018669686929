import React from "react"
import { Link } from "gatsby"

//Styles
import "../styles/reset.scss";
import "../styles/app.scss";
import * as styles from "../styles/index.module.scss";

//Components
import Page from "../components/page.js";
import Menu from "../components/menu.js";
import Assistant from "../components/assistant.js";
import Button from "../components/button.js";

//Assets

import imgProjectWavepool from "../images/projects/Wavepool_Showcase_1.jpg";
import imgProjectKapps from "../images/projects/Kapps_Showcase_1.jpg";
import imgProjectSDA from "../images/projects/SDA_Showcase_1.jpg";
import imgProjectNumbergram from "../images/projects/Numbergram_Showcase_1.jpg";
import imgProjectDigitalTickets from "../images/projects/Digital_Tickets_Showcase_1.jpg";
import imgProjectMCR from "../images/projects/MCR_Showcase_1.jpg";
import imgProjectSnapquote from "../images/projects/Snapquote_Showcase_1.jpg";
import imgProjectKitstart from "../images/projects/Kitstart_Showcase_1.jpg";
import imgProjectBOP from "../images/projects/BOP_Showcase_1.jpg";

import imgMe from "../images/me.jpg";
import imgWorkshop from "../images/img-design-workshop.jpg";
import vidMe from "../images/me.mp4";

import iconScroll from "../images/icon-scroll.svg";
import iconDesign from "../images/icon-design.svg";
import iconProduct from "../images/icon-product.svg";
import iconStrategy from "../images/icon-strategy.svg";
import iconCode from "../images/icon-code.svg";
import iconBuild from "../images/icon-build.svg";
import iconLearn from "../images/icon-learn.svg";
import iconDone from "../images/icon-done.svg";

//Helpers
import { throttle } from 'lodash';

export default class extends React.Component {

  constructor(props) {
    super(props);

    this.isBrowser = typeof window !== "undefined";

    this.state = {
      openingMessageIndex: 0,
      pagePosition: 0,
    }

    this.openingMessages = [
      "UX Designer / Digital Expert",
      "I DESIGN END-TO-END",
      "I BRING TEAMS TOGETHER",
      "I DISCOVER & TEST",
      "I PLAN & STRATEGISE",
      "I CODE & EXPERIMENT"
    ]

    this.pageElementRefs = [];

    this.throttledScrollFunction = throttle(() => { this.handleScroll() }, 500);

    //this.setState({tabIndexAbout: index});

  }

  cycleOpeningMessages() {

    let i = this.state.openingMessageIndex;

    if (i >= (this.openingMessages.length - 1))
      i = 0;
    else
      i++;

    this.setState({ openingMessageIndex: i }, () => {
      if (i != 0)
        setTimeout(() => { this.cycleOpeningMessages(); }, 1000);
    });
  }

  componentDidMount() {
    this.cycleOpeningMessages();

    if (this.isBrowser) {
      window.addEventListener('scroll', this.throttledScrollFunction);
      window.addEventListener('resize', this.throttledScrollFunction);

      this.pageElementRefs = document.getElementsByClassName("page");
      this.handleScroll();
    }


  }

  componentWillUnmount() {
    if (this.isBrowser) {
      window.removeEventListener('scroll', this.throttledScrollFunction);
      window.removeEventListener('resize', this.throttledScrollFunction);
    }
  }

  handleScroll(event) {

    //&& this.purchaseRef

    if (this.isBrowser && this.pageElementRefs.length > 0) {

      let closestElement = [];

      for (let i = 0; i < this.pageElementRefs.length; i++) {

        let pos = this.pageElementRefs[i].getBoundingClientRect().top;

        let distance = pos;
        if (pos < 0)
          distance = pos * -1;

        //console.log(`${i}: ${this.pageElementRefs[i].getBoundingClientRect().top}`);
        if (closestElement.length == 0 || distance < closestElement[1]) {
          closestElement = [i, distance];
        }

      }

      for (let i = 0; i < this.pageElementRefs.length; i++) {

        if (i != closestElement[0]) {
          this.pageElementRefs[i].removeAttribute("active");
        }
        else {
          this.pageElementRefs[i].setAttribute("active", "");
          this.setState({ pagePosition: i });
          //console.log(i);
        }
      }
    }
  }

  scrollDownFromTop(e) {
    e.preventDefault();

    if (this.isBrowser) {
      document.getElementById("scrollDown").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  render() {
    return (
      <>
        <Menu pageIndex={this.state.pagePosition} totalPages={12}>Menu</Menu>
        <Page first>
          <div>
            <div>
              <h1 className={"h3"}>Zenon Milou</h1>
              <h2 className={"h1"}>{this.openingMessages[this.state.openingMessageIndex]}</h2>
            </div>
            <div className={styles.scrollIconContainer}>
              <a id="scrollDown" title="Scroll down" onClick={(e) => { this.scrollDownFromTop(e) }}><img src={iconScroll} alt="Mouse with middle scroll wheel emphasised" /></a>
            </div>
          </div>
          <div></div>
          <div style={{ backgroundImage: "url(" + imgMe + ")" }}>
            <Assistant />
          </div>
        </Page >
        <Page>
          <div>

            <div>
              <h2>About</h2>
              <p>I’m a Sydney based designer who is passionate about solving problems. My established 10 year career spans government, banking, insurance, and event services industries.</p>

              <div className={styles.calloutItems}>
                <ul>
                  <li className={styles.calloutItem}>
                    <img src={iconDesign} />
                    <span>
                      <span className={styles.calloutItemTitle}>End-to-end UX designer</span> <br />
                      <span className={styles.calloutItemDesc}>Led design activities from discovery to delivery, including user research, interaction design, usability testing and visual design.</span>
                    </span>
                  </li>
                  <li className={styles.calloutItem}>
                    <img src={iconProduct} />
                    <span>
                      <span className={styles.calloutItemTitle}>Product leader</span> <br />
                      <span className={styles.calloutItemDesc}>Prioritised and shaped product roadmaps, assessed competitive landscapes, modernised legacy systems. </span>
                    </span>
                  </li>
                  <li className={styles.calloutItem}>
                    <img src={iconStrategy} />
                    <span>
                      <span className={styles.calloutItemTitle}>Strategic thinker</span> <br />
                      <span className={styles.calloutItemDesc}>Contributed to state-wide government policy and guideline creation, brought stakeholders together to reach shared goals.</span>
                    </span>
                  </li>
                  <li className={styles.calloutItem}>
                    <img src={iconCode} />
                    <span>
                      <span className={styles.calloutItemTitle}>Developer with AI experience</span> <br />
                      <span className={styles.calloutItemDesc}>Degree in Computer Science, can develop solutions end-to-end and have worked amongst a team that owns AI governance and guidance.</span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>

          </div>
          <div></div>
          <div className={`${styles.mobileHide} ${styles.video}`}>
            <video type="video/mp4" src={vidMe} autoPlay controls muted loop></video>
          </div>
        </Page>
        <Page>
          <div>
            <div>
              <h2>Moshtix Control Room</h2>
              <div className={styles.projectDetailsContainer}>
                <h3 className={"h4"}>OVERVIEW</h3>
                <p>A new way for clients to build and manage their events, opening up the Moshtix ecosystem to the public by allowing the self service creation of events by anyone. As lead designer, redesigned the interface for creating events, managing ticket inventory, navigating the platform and managing accounts. Additionally, established new design system which accelerated future product development.</p><br />

                <h3 className={"h4"}>OUTCOME</h3>
                <p>Business expansion to new self-service customers, improved efficiency for clients and internal staff, and foundation laid for incremental introduction of new suite of products to platform.</p>
              </div>
              <div className={styles.projectButtonContainer}>
                <Button link="https://vimeo.com/992425859">See the process</Button>
              </div>
            </div>
          </div>
          <div></div>
          <div style={{ backgroundImage: "url(" + imgProjectMCR + ")" }}></div>
        </Page >
        <Page>
          <div>
            <div>
              <h2>Wavepool</h2>
              <div className={styles.projectDetailsContainer}>
                <h3 className={"h4"}>OVERVIEW</h3>
                <p>A mobile app that lets professionals network better at conferences and meetups, by seeing the professional profiles of those around them. Designed the experience, created visual assets, coded the mobile app and back-end services, managed cloud hosting and deployment, user tested at real meetups.</p><br />

                <h3 className={"h4"}>OUTCOME</h3>
                <p>Validated idea through pilot, seeing patrons helped at meetups connect with their peers.</p>
              </div>
              <div className={styles.projectButtonContainer}>
                <Button link="https://vimeo.com/992451729">See the process</Button>
              </div>
            </div>
          </div>
          <div></div>
          <div style={{ backgroundImage: "url(" + imgProjectWavepool + ")" }}></div>
        </Page >
        <Page>
          <div>
            <div>
              <h2>State Digital Assets</h2>
              <div className={styles.projectDetailsContainer}>
                <h3 className={"h4"}>OVERVIEW</h3>
                <p>SDAs are digital platforms, services or data that are used by more than one NSW government agency. They are the building blocks of efficient, proactive, customised, and seamless government services. Contributed to desktop research, user research, prototyping, policy & content writing, workshop facilitation and stakeholder management across multiple Government agencies. </p><br />

                <h3 className={"h4"}>OUTCOME</h3>
                <p>Target state is a government with interconnected digital platforms and data, supported by policy and a strong partnership ecosystem to deliver social, environmental and productivity benefits through fewer and better systems.</p>
              </div>
              <div className={styles.projectButtonContainer}>
                <Button link="https://vimeo.com/992451935">See the process</Button>
              </div>
            </div>
          </div>
          <div></div>
          <div style={{ backgroundImage: "url(" + imgProjectSDA + ")" }}></div>
        </Page >
        <Page>
          <div>
            <div>
              <h2>Kapps</h2>
              <div className={styles.projectDetailsContainer}>
                <h3 className={"h4"}>OVERVIEW</h3>
                <p>A smart TV app which displays realtime captions over free-to-air TV in any language. Iteratively designed prototypes to test and validate idea - including mobile, tablet and smart TV interfaces. Developed proof of concept prototype and production ready application.</p><br />

                <h3 className={"h4"}>OUTCOME</h3>
                <p>Opened up a new world of content for non-English speakers. Dad able to understand Seinfeld.</p>
              </div>
              <div className={styles.projectButtonContainer}>
                <Button link="https://vimeo.com/992451495">See the process</Button>
              </div>
            </div>
          </div>
          <div></div>
          <div style={{ backgroundImage: "url(" + imgProjectKapps + ")" }}></div>
        </Page >
        <Page>
          <div>
            <div>
              <h2>Bingle Snapquote</h2>
              <div className={styles.projectDetailsContainer}>
                <h3 className={"h4"}>OVERVIEW</h3>
                <p>Designed mobile web app allowing users to easily obtain a car insurance quote by capturing an image of their licence. Performed continuous research on desirability of the idea, interaction design from sketch to prototype, and regular usability testing which was fed immediately back into the designs and implementation.</p><br />

                <h3 className={"h4"}>OUTCOME</h3>
                <p>Validated concept, simplifying insurance process for Australian customers.</p>
              </div>
            </div>
          </div>
          <div></div>
          <div style={{ backgroundImage: "url(" + imgProjectSnapquote + ")" }}></div>
        </Page >
        <Page>
          <div>
            <div>
              <h2>Numbergram</h2>
              <div className={styles.projectDetailsContainer}>
                <h3 className={"h4"}>OVERVIEW</h3>
                <p>A puzzle game where you add, subtract, multiply or divide four given numbers to make 10. Inspired by the game played by Sydney train commuters using carriage numbers to pass the time.
                  Designed the experience, created visual assets, coded the front-end UI and back-end services, implemented internationalisation, managed cloud hosting and deployment, and conducted marketing activities.</p><br />

                <h3 className={"h4"}>OUTCOME</h3>
                <p>Game enjoyed daily by it's fan-base. It's audience now includes school teachers and children learning math.</p>
              </div>
              <div className={styles.projectButtonContainer}>
                <Button link="https://numbergram.app">Go to website</Button>
              </div>
            </div>
          </div>
          <div></div>
          <div style={{ backgroundImage: "url(" + imgProjectNumbergram + ")" }}></div>
        </Page >
        <Page>
          <div>
            <div>
              <h2>Moshtix Digital Tickets</h2>
              <div className={styles.projectDetailsContainer}>
                <h3 className={"h4"}>OVERVIEW</h3>
                <p>Responsive web-based digital tickets which support mobile, tablet and management on desktop. Conducted research to understand journey of customers (with emphasis on multi-ticket purchasers) and the impact of paper and non-responsive tickets at venue entry. Iteratively designed solution (including interaction and visual design), working with clients to gather feedback and testing in venue trials to evaluate usability.</p><br />

                <h3 className={"h4"}>OUTCOME</h3>
                <p>Reduced friction for multi-ticket holders, increasing speed of entry, benefiting customers and event organisers.</p>
              </div>
            </div>
          </div>
          <div></div>
          <div style={{ backgroundImage: "url(" + imgProjectDigitalTickets + ")" }}></div>
        </Page >
        <Page>
          <div>
            <div>
              <h2>Kitstart</h2>
              <div className={styles.projectDetailsContainer}>
                <h3 className={"h4"}>OVERVIEW</h3>
                <p>An e-commerce store selling an essential kitchenware kit delivered direct to customers. For those leaving the nest, starting the next chapter as a couple, or gifting to someone that is; takes the stress away from setting up a new kitchen. Designed the business model, designed the experience, created visual assets, coded the front-end UI and created video content for marketing.</p><br />

                <h3 className={"h4"}>OUTCOME</h3>
                <p>Validated concept and established a new business with a charitable component to help those in need.</p>
              </div>
            </div>
          </div>
          <div></div>
          <div style={{ backgroundImage: "url(" + imgProjectKitstart + ")" }}></div>
        </Page >
        <Page>
          <div>
            <div>
              <h2>My Lost Bop</h2>
              <div className={styles.projectDetailsContainer}>
                <h3 className={"h4"}>OVERVIEW</h3>
                <p>A digital children's picture book to introduce kids to different music genres. It follows Zack as he tries to remember his favourite song, who enlists the help of friends and family who all think they know what it was. Developed concept, wrote story, designed page layouts, curated playlists, designed 3d experience, and developed React app.</p><br />
                <h3 className={"h4"}>OUTCOME</h3>
                <p>Published prototype to validate concept and support submission to publishers.</p>
              </div>
              <div className={styles.projectButtonContainer}>
                <Button link="https://www.linkedin.com/posts/zenon-milou_music-ai-childrensbook-activity-7241577898795487232-JhM1">See the process</Button>
              </div>
            </div>
          </div>
          <div></div>
          <div style={{ backgroundImage: "url(" + imgProjectBOP + ")" }}></div>
        </Page >
        <Page>
          <div>
            <div>
              <h2>Recent activity</h2>
              <div className={styles.calloutItems}>
                <ul>
                  <li className={styles.calloutItem}>
                    <img src={iconDone} />
                    <span>
                      <span className={styles.calloutItemTitle}>Completed USYD 'Leading Responsible Innovation' course</span> <br />
                      <span className={styles.calloutItemDesc}>In-person training focused on strategic design and system thinking.</span>
                    </span>
                  </li>
                  <li className={styles.calloutItem}>
                    <img src={iconDone} />
                    <span>
                      <span className={styles.calloutItemTitle}>Completed 'Responsible AI' TAFE micro-skill</span> <br />
                      <span className={styles.calloutItemDesc}>Online training focused on AI principles, policies and procedures, governance, and creating ethical AI applications.</span>
                    </span>
                  </li>
                  <li className={styles.calloutItem}>
                    <img src={iconDone} />
                    <span>
                      <span className={styles.calloutItemTitle}>Completed Vision Australia accessibility training</span> <br />
                      <span className={styles.calloutItemDesc}>In-person training focused on practical accessibility guidance for designers and developers.</span>
                    </span>
                  </li>
                </ul>
              </div>
              <div>
                <div className={styles.projectButtonContainer}>
                  <Button link="https://www.linkedin.com/in/zenon-milou/" >Connect on LinkedIn</Button>
                </div>
              </div>
            </div>
          </div>
          <div></div>
          <div style={{ backgroundImage: "url(" + imgWorkshop + ")" }}></div>
        </Page >
      </>
    )
  }
}